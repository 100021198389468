<template>
  <div class="row">
    <div class="col-12 g">
        <div class="card">
            <div class="card-header">
                <h5>
                    سجل المستخدمين <button class="btn btn-success" v-b-modal.create style="width: 30px; border-radius: 50%; padding: 0px; height: 30px">
                     <i class="fa fa-plus"></i>
                    </button>
                </h5>
            </div>
            <div class="card-body">
                <div class="col-12 table-responsive">
                    <table class="table table-hover table-bordered table-sm">
                        <thead>
                            <th>المستخدم</th>
                            <th>رقم الجوال</th>
                            <th>تاريخ الانضمام</th>
                            <th>
                                عدد الطلبات
                            </th>
                        </thead>
                        <tbody>
                            <tr v-for="u in users" :key="u._id">
                                <td>
                                    {{ u.name }}
                                </td>
                                <td>
                                    {{ u.phone }}
                                </td>
                                <td>
                                    {{ u.joindate }}
                                </td>
                                <td>
                                    {{ u.orders }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="create" title="اضافة مستخدم" hide-footer>
        <div class="form-group">
          <label for="">اسم المدرسة</label>
          <input type="text"
            class="form-control" v-model="create.name">
        </div>
        <div class="form-group">
          <label for="">رقم الجوال (بالصيفة الدولية)</label>
          <input type="text"
            class="form-control" v-model="create.phone">
        </div>
        <div class="form-group">
          <label for="">كلمة المرور</label>
          <input type="text"
            class="form-control" v-model="create.password">
        </div>
        <div>
            <div class="alert bg-light-warning text-center g">
                قم بحفط هده البيانات قبل اضافة المستخدم.
            </div>
        </div>
        <div class="col-12 text-center">
            <button class="btn btn-success" @click="createNow()">
                <i class="fa fa-check"></i>
                اضافة المستخدم
            </button>
        </div>
    </b-modal>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { VBModal, BModal, BFormInput, BDropdown, BDropdownItem } from "bootstrap-vue";
export default {
    components: {
        // Layouts
        BDropdown, BDropdownItem,
        BModal,BFormInput},
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
            users: [],
            create: {}
        }
    },
    created(){
        var g = this;
        $.post(api + '/reseller/users', {
            jwt: this.user.jwt
        }).then(function(r){
            g.users = r.response;
        })
    },
    methods: {
        createNow(){
            var g = this;
            $.post(api + '/user/auth/register', {
                phone: g.create.phone,
                password: g.create.password,
                name: g.create.name,
                code: g.user.code,
                from_reseller: 'yes'
            }).then(function(r){
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    alert("تم انشاء الحساب بنجاح", 100)
                }
            }).fail(function(){
                alert("حدث مشكلة في الاتصال", 200)
            })
        }
    }
}
</script>

<style>

</style>